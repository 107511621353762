@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
/*@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap");*/
/*@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");*/

@font-face {
  font-family: Poppins-Bold;
  src: url("../src/assets/font-family/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins-ExtraBold;
  src: url("../src/assets/font-family/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: Poppins-Light;
  src: url("../src/assets/font-family/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../src/assets/font-family/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins-Regular;
  src: url("../src/assets/font-family/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url("../src/assets/font-family/Poppins-SemiBold.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
button,
input {
  font-family: "Roboto", sans-serif;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: #050505 !important;
}

.Toastify__toast-theme--dark {
  background-color: #00000060 !important;
}

.MuiInput-root:after {
  border-bottom: none !important;
}
.messagesetting {
  color: white !important;
}

.chat-ul {
  display: inline-flex !important;
  list-style: none !important;
}
